import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const FotografiPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO title="Susține și pe alții" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <p>
            Coronavirus ne afectează pe toți. Susține și alte studiouri sau
            fotografi.
          </p>
          <hr />
          <p>
            Această pagină va fi populată în curând. Mai strângem câteva nume!
          </p>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <FotografiPage location={props.location} data={data} {...props} />
    )}
  />
);
